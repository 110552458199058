import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ShoppingCartProvider } from "./components/_default/context/ShoppingCartContext"
import store from "./store/store"
import Redirect from "./components/_default/utilities/Redirect"
import TagManager from 'react-gtm-module'
// Pages
import Webpage2Avaleht from "./pages/Webpage2Avaleht";
import Webpage12Lehte_ei_leitud from "./pages/Webpage12Lehte_ei_leitud";
import Webpage14Tingimused from "./pages/Webpage14Tingimused";

var baseURL = 'https://web.kassahaldus.ee/webstoreApi';
var axiosHeaders = {
    Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNjA4MmFmZmI4MGM2OWNhMjEzZTg5NDQ1MmE4OGE4NmFmOGFjMTg5Mzc2Y2I0ZTg5YzA2NjU5YjQyMDUyYmZhYmExYmE1NTU0MmMyNzI3ZGQiLCJpYXQiOjE3MzcyOTc0MjAuNjUwMzcyLCJuYmYiOjE3MzcyOTc0MjAuNjUwMzc2LCJleHAiOjE3MzczODM4MjAuNjQ1NTMsInN1YiI6IjY1Iiwic2NvcGVzIjpbXX0.QDQDn3MLpddonYjNCU8ZtUk9NRyrL9ag0mtI36Cn4JXRFns5bNBP2fFSMZYzrvdytEd2tJSb1UBGV6bpjBD-g7S3CG_D3vBrGVqtPKok5fBPTmnY8PgirME35DOPQctiK_ecFoz7zEvB0ShOtE_CEdu72RGZJglv_k4ie3C_zJZm4k-Duh3h0W0JBzY3fsH0AriGmMObSOVvdHRZ1y8UrOPYQ2gLdeOKffMbHbOglIY8AnBg9tRnRM9ZDGqem83nJaqMRCa0l7dqp2OosAkqC-X7VZ4JEOAf7ryc1tDMqvIAyOLY0Ym_J-OF9NwugSDxGydcKUJEFmvXl4djT3yD_MooX33o0MvoKUS4X8dasp_lOoboZFblxjNP-6DdJPcjs0P_wON_zlv4fbq69nYusDotMyY9AsPKWnKTyzCEcJC_l5aHl9dJkEjZ8dbl5OeslMTcYmyJvG-G5pSUX7E24i9wWp7nIbyo8rfjdUmDkDU1hrfpm0bwl_kDfUFWGrFCVGOMktEMCUU2-Y_nThP_Gjuz_IJ1lEcoZCwnJ87bCTJ-ZepiMfgVt18qTmGMRVE3OfJLzdru-XktJbqQI6y295MGR4Jz2SLAcj0Sq5po64ROQXLKW07LLrv14F6KwshaS73HvLRizsE8aRhVjJ11gAPlkIykSQeGgYfqBfxqszo',
    'Access-api-key': "eyJpdiI6InRiZ0tVcFpFYzNGWWNJK0lNL1NFenc9PSIsInZhbHVlIjoiTFlHNXhtMTlsREZvZEYzV0NuSzMzdz09IiwibWFjIjoiYTJlZTM2N2RiODRmNzJjZjVkNWYzYzQyZDNiZDc2NjFjYWZmODUzMThlOGRlMmM1MTNkN2M2YzE3OGJhMWVkMSIsInRhZyI6IiJ9",
    'Access-api-id': "eyJpdiI6IklnK3h0Y08rMmsyYTJGR3pvblJnVEE9PSIsInZhbHVlIjoiWWkvWWRhWGRrRDA4QXl4MGNhZUxRZz09IiwibWFjIjoiZjBkZDBiODY5NTA0ZTBhZTMxMTYzY2U4MjFkNWI0Mjc1ZTA2MjEzYTc2ZDliYTczOWQ2ZjIzZTg5MmM2Mzk0ZCIsInRhZyI6IiJ9",
    'Access-Control-Allow-Origin': "*"
 };


window.axios = require('axios').create({
  baseURL: baseURL,
  headers: axiosHeaders
});
const tagManagerArgs = {
    gtmId: "GTM-KHPWXL9"
}
TagManager.initialize(tagManagerArgs)
    
export default function App() {
    window.dataLayer.push({
        event: 'pageview'
    });
    return (
        <Provider store={store}>
            <ShoppingCartProvider>
                <BrowserRouter basename="/">
                    <Routes>
                                            <Route path="/et/" element={<Webpage2Avaleht />} exact />;
                                                                    <Route path="/et/*" element={<Webpage12Lehte_ei_leitud />} exact />;
                                                <Route path="*" element={<Webpage12Lehte_ei_leitud />} exact />;
                                                                    <Route path="/et/tingimused" element={<Webpage14Tingimused />} exact />;
                                                                
                                                            </Routes>
                </BrowserRouter>
            </ShoppingCartProvider>
        </Provider>
    );
}